<template>
  <div class="card h-100">
    <img v-if="service.cover_image"
         :src="service.cover_image"
         class="card-img-top mx-auto"
         style="height: auto; width: 100%">
    <img v-else
         :src="user.clinic.logo"
         class="card-img-top mx-auto p-4"
         style="height: auto; width: 100%">
    <div class="card-body pb-0 text-center">
      <h5>{{service.name}}</h5>
      <span class="d-block">
          <small v-if="service.price > 0" class="fw-bold">{{service.price_display}}</small>
          <small v-else class="fw-bold">FREE</small>
          <span v-if="service.booking_options.client_booking_allowed">
            <i class="far fa-globe-europe ms-2 text-primary"></i>
            <small>Public</small>
          </span>
          <span v-else>
            <i class="far fa-globe-europe ms-2"></i>
            <small>Private</small>
          </span>
        </span>
      <span class="d-block small mb-2">
          <b>{{service.practitioner_services.length}}</b> practitioners offering this service.
      </span>
    </div>
    <div class="card-footer pt-0 text-center">
      <button class="btn btn-sm btn-light" @click="editService">
        <i class="fa fa-cog me-1"></i>
        Configure
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service", "user"],
  methods: {
    deleteService() {
      var confirmed = confirm("Are you sure you wish to delete this service? As the clinic owner, deleting this service will also remove it for all subscribed practitioners.");
      if (confirmed) {
        this.$axios.delete(process.env.VUE_APP_API_URL + "/settings/services/api/" + this.service.id)
          .then(({data}) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("updated");
          });
      }
    },
    editService() {
      this.$router.push({ name: "SettingsViewClinicService", params: {id: this.service.id} });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
};
</script>
