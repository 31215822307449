<template>
  <div>
    <div class="d-flex w-100 mb-3">
      <h4 class="my-auto fw-bold text-primary">New Package</h4>
      <button
          class="btn btn-light ms-auto ms-auto my-auto"
          type="button"
          @click="cancelAdd"
      >
        <i class="far fa-arrow-left"></i>
        Back to Services & Packages
      </button>
    </div>
    <package-form v-model="package" @submit="savePackage" @cancel="cancelAdd" />
  </div>
</template>

<script>
import PackageForm from "./PackageForm";
export default {
  props: ["path", "user"],
  data() {
    return {
      loading: true,
      sendReminder: false,
      sendFollowUp: false,
      package: this.getDefaultPackage(),
    };
  },

  methods: {
    cancelAdd() {
      this.$emit("cancel");
    },
    savePackage() {
      if (this.package.price > 0 && this.package.price < 1){
        this.$EventBus.$emit("alert", { color: 'danger', message: 'Error: Minimum price of 1.00. Free packages are also available.'})
      } else {
        this.$axios.post(process.env.VUE_APP_API_URL + "/settings/packages/api", this.package)
                .then(({ data }) => {
                  this.$EventBus.$emit("alert", data);
                  this.$emit("saved");
                });
      }
    },
    getDefaultPackage() {
      return {
        services: [],
        instalments: [{total: this.price}],
        currency: this.$stash.currencies[0],
        booking_options: {
          client_booking_allowed: false,
          client_booking_allowed_portal: false,
          client_booking_allowed_snippets: false,
          price: 0.0,
          allow_instalments: false,
        }
      };
    },
  },
  components: {
    PackageForm
  }
};
</script>
