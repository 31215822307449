<template>
  <div class="card h-100">
    <img v-if="p.cover_image"
         :src="p.cover_image"
         class="card-img-top mx-auto"
         style="height: auto; width: 100%">
    <img v-else
         :src="user.clinic.logo"
         class="card-img-top mx-auto p-4"
         style="height: auto; width: 100%">
    <div class="card-body pb-0 text-center">
      <h5>{{p.name}}</h5>
      <span class="d-block">
        <small v-if="p.price > 0" class="fw-bold">{{p.services.length}} services ({{p.price_display}})</small>
        <small v-else class="fw-bold">FREE</small>
        <span v-if="p.booking_options.client_booking_allowed">
          <i class="far fa-globe-europe ms-2 text-primary"></i>
          <small>Public</small>
        </span>
        <span v-else>
          <i class="far fa-globe-europe ms-2"></i>
          <small>Private</small>
        </span>
      </span>
    </div>
    <div class="card-footer text-center pt-0">
      <button class="btn btn-sm btn-light" @click="editPackage">
        <i class="fa fa-cog me-1"></i>
        Configure
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["p", "user"],
  methods: {
    editPackage(){
      this.$router.push({ name: "SettingsViewClinicPackage", params: {id: this.p.id} });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
};
</script>
