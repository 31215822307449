<template>
  <div class="container-fluid" v-if="$can('manage clinic services')">
    <div class="mb-3" v-if="!showAdd">
      <input type="text" class="form-control" placeholder="Search services & packages..." v-model="searchText"/>
    </div>

    <add-service
        v-if="showAddService"
        :user="user"
        :path="path"
        :template="cloneServiceTemplate"
        @cancel="resetAddService"
        @saved="resetAddService" />

    <add-package
        v-if="showAddPackage"
        :user="user"
        :path="path"
        @cancel="showAddPackage = false"
        @saved="showAddPackage = false" />

    <div class="row" v-if="!showAdd">
      <div class="col col-md-6">
        <services-list
            :path="path"
            :user="user"
            :searchText="searchText"
            @cancelAdd="resetAddService"
            @showAdd="showAddService = true"
            @clone="cloneService"
        ></services-list>
      </div>
      <div class="col col-md-6">
        <packages-list
            :path="path"
            :user="user"
            :searchText="searchText"
            @cancelAdd="showAddPackage = false"
            @showAdd="showAddPackage = true"
        ></packages-list>
      </div>
    </div>
  </div>
</template>

<script>

import ServicesList from "./clinic-services-partials/ServiceList";
import PackagesList from "./clinic-services-partials/PackageList";
import AddService from "./services-partials/AddService";
import AddPackage from "./services-partials/AddPackage";

export default {
  props: [],
  computed: {
    path: function () {
      return this.$router.currentRoute.fullPath;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    showAdd() {
      return this.showAddService || this.showAddPackage;
    }
  },
  data() {
    return {
      showAddService: false,
      showAddPackage: false,
      cloneServiceTemplate: null,
      searchText: "",
    };
  },
  methods: {
    cloneService(service) {
      this.cloneServiceTemplate = service;
      this.showAddService = true;
    },
    resetAddService() {
      this.showAddService = false;
      this.cloneServiceTemplate = false;
    }
  },
  filters: {},
  mounted() {},
  components: {
    AddPackage,
    AddService,
    PackagesList,
    ServicesList,

  },
};
</script>