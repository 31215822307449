<template>
  <div class="card border-0 bg-light shadow-sm mb-4">
      <div class="card-body">
        <h5 class="text-primary fw-bold mb-0">Packages</h5>
        <p class="alert alert-primary">
          <i class="far fa-info-circle me-1"></i>
          You can build packages using your existing services. Use any combination of services to create a package.
        </p>
        <div class="row mb-3">
          <div class="col">
            <button
                class="btn btn-outline-primary"
                @click="$emit('showAdd')"
            >
              <i class="far fa-plus me-1"></i>
              Add Package
            </button>
          </div>
          <div class="col d-flex">
            <div class="btn-group ms-auto ms-auto my-auto">
              <button
                  class="btn btn-sm"
                  @click="listView = !listView"
                  :class="listView ? 'btn-primary' : 'btn-outline-primary'"
              >
                <i class="far fa-list me-1"></i>
                List
              </button>
              <button
                  class="btn btn-sm"
                  @click="listView = !listView"
                  :class="!listView ? 'btn-primary' : 'btn-outline-primary'"
              >
                <i class="far fa-th-large me-1"></i>
                Grid
              </button>
            </div>
          </div>
        </div>
        <busy :visible="loading" />
        <div v-if="!loading">
          <div v-if="packages.length === 0">
            <div class="row">
              <div class="col-6 mx-auto text-center">
                <p>No packages found.</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="!listView">
              <div class="row">
                <div class="col-6 mb-3" v-for="p in filteredPackages">
                  <package-card :p="p"
                                :user="user"
                                @deleted="fetchPackages"
                  ></package-card>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <table class="table table-hover mb-0">
                      <draggable v-model="packages" @end="orderUpdate()" tag="tbody">
                      <tr v-for="p in filteredPackages" :key="p.id">
                        <td class="table-col-min">
                          <img v-if="p.cover_image"
                               :src="p.cover_image"
                               class="rounded mx-auto"
                               style="height: 35px; width: 35px;">
                          <img v-else
                               :src="user.clinic.logo"
                               class="rounded mx-auto"
                               style="height: 35px; width: 35px;">
                        </td>
                        <td class="align-middle text-capitalize">{{p.name}}</td>
                        <td class="align-middle text-end text-right">
                        <span v-if="p.booking_options.client_booking_allowed" class="badge bg-info">
                          Public
                        </span>
                          <button class="btn btn-sm btn-light ms-3 ms-3" @click="editPackage(p)">
                            <i class="fa fa-cog me-1"></i>
                            Configure
                          </button>
                          <button class="btn btn-sm btn-outline-danger ms-2" @click="deletePackage(p)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                      </draggable>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
</template>

<script>
import AddPackage from "../services-partials/AddPackage";
import PackageCard from "./PackageCard";
import draggable from 'vuedraggable'

export default {
  props: ["path", "user", "searchText"],
  data() {
    return {
      loading: true,
      listView: true,
      packages: [],
    };
  },

  methods: {
    fetchPackages() {
      this.loading = true;
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/packages/api").then(({ data }) => {
        this.packages = this.orderByDisplayOrder(data);
        this.loading = false;
      });
    },
    cancelAddPackage() {
      this.$emit("cancelAdd");
    },
    savedPackage() {
      this.fetchPackages();
      this.$emit("cancelAdd");
    },
    editPackage(p){
      this.$router.push({ name: "SettingsViewClinicPackage", params: {id: p.id} });
    },
    deletePackage(p) {
      var confirmed = confirm("Are you sure you wish to delete this package?");
      if (confirmed) {
        this.$axios
            .delete(
                process.env.VUE_APP_API_URL + "/settings/packages/api/" + p.id
            )
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.fetchPackages();
            });
      }
    },
    savePackages(packages) {
       this.$axios
            .post(process.env.VUE_APP_API_URL + "/settings/packages/api/save-order", {packages: packages})
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
            });
    },
    orderByDisplayOrder(packages) {
      return packages.sort((a, b) => {
        return a.display_order - b.display_order;
      });
    },
    orderUpdate() {
      let newPackages = [];
      this.packages.forEach((p, index) => {
        p.display_order = index;
        newPackages.push(p);
      });
      this.savePackages(newPackages);
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
  computed: {
    filteredPackages() {
      if(!this.searchText || this.searchText.length < 1) {
        return this.packages;
      }
      return this.packages.filter( (p) => {
        return p.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    }
  },
  mounted() {
    this.fetchPackages();
  },
  components: {
    PackageCard,
    AddPackage,
    draggable
  }
};
</script>
