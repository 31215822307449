<template>
  <div>
    <tree-select
      v-model="value.id"
      :disable-branch-nodes="true"
      :show-count="true"
      :show-count-of="'LEAF_DESCENDANTS'"
      :options="formSelectOptions"
      placeholder="Select a form from the list"
      :required="req"
    />
  </div>
</template>

<script>
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props: ["value", "req"],
  data() {
    return {
      forms: [],
      form: null,
      categories: [
        "Adult Health Intake Questionnaire",
        "Child Health Intake Questionnaire",
        "Food Diary",
        "Other",
      ],
    }
  },
  methods: {
    fetchForms() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/forms/api/public").then(({ data }) => {
        this.forms = data.filter((form) => form.status == "published");
      });
    },
    getClinicFormsByCategory(c) {
      var options = [];
      if (this.forms) {
        this.forms
            .filter(function (el) {
              return el.category === c && !el.is_public;
            })
            .forEach((f) => {
              options.push({
                label: f.title,
                id: f.id,
              });
            });
      }

      return options;
    },
    getPublicFormsByCategory(c) {
      var options = [];
      if (this.forms) {
        this.forms
            .filter(function (el) {
              return el.category === c && el.is_public;
            })
            .forEach((f) => {
              options.push({
                label: f.title,
                id: f.id,
              });
            });
      }
      return options;
    },
  },
  computed: {
    clinicFormSelectOptions() {
      var options = [];
      this.categories.forEach((category) => {
        const children = this.getClinicFormsByCategory(category);
        if (children.length > 0) {
          options.push({
            id: category + "-clinic",
            label: category,
            children: children,
          });
        }
      });

      return options;
    },
    publicFormSelectOptions() {
      var options = [];
      this.categories.forEach((category) => {
        const children = this.getPublicFormsByCategory(category);
        if (children.length > 0) {
          options.push({
            id: category + "-public",
            label: category,
            children: children,
          });
        }
      });

      return options;
    },
    formSelectOptions() {
      var options = [
        {
          id: -1,
          label: "My Clinic Form Templates",
          children: this.clinicFormSelectOptions,
        },
        {
          id: -2,
          label: "Swandoola Public Form Templates",
          children: this.publicFormSelectOptions,
        },
      ];
      return options;
    },
  },
  watch: {
    value() {
      const vm = this;
      this.form = this.forms.find(form => form.id === vm.value.id);
      this.$emit("input", this.form);
    }
  },
  mounted() {
    this.fetchForms();
  },
  components: {
    TreeSelect,
  }
};
</script>

<style>
  .vue-treeselect__control{
    height: 44px;
    padding-top: 4px;
    border: 1px solid #b0bfbf !important;
  }
</style>
