<template>
  <div>
    <div class="d-flex w-100 mb-3">
      <h4 class="my-auto fw-bold text-primary">New Service</h4>
      <button
          class="btn btn-light ms-auto ms-auto my-auto"
          type="button"
          @click="cancelAdd"
      >
        <i class="far fa-arrow-left"></i>
        Back to Services & Packages
      </button>
    </div>
    <service-form v-if="loadedCurrency"
                  v-model="service"
                  @submit="saveService"
                  @cancel="cancelAdd" />
  </div>
</template>

<script>
import ServiceForm from "./ServiceForm";
export default {
  props: ["path", "user", "template"],
  data() {
    return {
      emailTemplates: [],
      service: this.template ?? this.getDefaultService(),
    };
  },
  computed: {
    loadedCurrency() {
      return this.$stash.currencies && this.$stash.currencies[0];
    }
  },
  watch: {
    loadedCurrency() {
      if (this.loadedCurrency && !this.service.currency.id) {
        this.service.currency = this.$stash.currencies[0];
      }
    }
  },
  methods: {
    cancelAdd() {
      this.$emit("cancel");
    },
    saveService() {
      if (this.service.price > 0 && this.service.price < 1){
        this.$EventBus.$emit("alert", { color: 'danger', message: 'Error: Minimum price of 1.00. Free services are also available.'})
      } else {
        this.$axios.post(process.env.VUE_APP_API_URL + "/settings/services/api", this.service)
                .then(({ data }) => {
                  this.service = this.getDefaultService();
                  this.$EventBus.$emit("alert", data);
                  this.$emit("saved");
                });
      }
    },
    getDefaultService() {
      const service = {
        name: null,
        description: null,
        cover_image: null,
        currency: this.$stash.currencies[0] ?? {},
        booking_options: {
          client_booking_allowed: true,
          client_booking_allowed_portal: true,
          client_booking_allowed_snippets: true,
          duration: "30",
          price: 0.0,
          allow_instalments: false,
          allow_standard: true,
          allow_video: true,
          allow_phone: true,
          cooldown_mins: 0,
          min_days_notice: 1,
          max_days_notice: 30,
          availability_in_person: {},
          availability_video: {},
          availability_phone: {},
        },
        instalments: [{total: this.price}],
        additional_items: [],
        practitioner_services: [],
        follow_up_email_template_id: null,
        payment_reminder_days: 1
      }

      if (!this.$store.getters['auth/user'].clinic.is_muc) {
        let mergeData = {
          id: null,
          user_id: this.$store.getters['auth/user'].id,
          user: this.$store.getters['auth/user'],
          instalments: service.instalments.map(i => Object.assign({}, i, {id: null})),
          booking_options: Object.assign({}, _.cloneDeep(service.booking_options), {id: null})
        }

        let practitionerService = {
          ...mergeData,
          ...service
        }

        practitionerService.practitioner_services = undefined;

        service.practitioner_services.push(practitionerService)
      }

      return service
    },
  },
  components: {
    ServiceForm,
  }
};
</script>
