<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" id="deleteServiceModal">
      <div class="modal-dialog modal modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-body">
            <loading v-if="loading" />
            <div v-else>
              <div v-if="safeToDelete">
                <p>Are you sure you want to delete this service? This is not reversible.</p>
                <div class="row">
                  <div class="col text-end">
                    <button class="btn btn-light me-2" @click="closeModal">
                      Cancel
                    </button>
                    <button class="btn btn-outline-danger" @click="performDelete">
                      <i class="far fa-trash me-1" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>Unable to delete this service. It is currently in use by the following packages:</p>
                <ul>
                  <li v-for="p in packages">
                    {{ p.name }}
                  </li>
                </ul>
                <div class="row">
                  <div class="col text-end">
                    <button class="btn btn-light" @click="closeModal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"],
  data() {
    return {
      loading: true,
      packages: null,
      safeToDelete: false
    };
  },
  methods: {
    checkSafeToDelete() {
      this.loading = true;
      this.$axios.get(process.env.VUE_APP_API_URL + '/settings/services/api/' + this.service.id + '/check-delete')
          .then(({data}) => {
            this.safeToDelete = data.safe_to_delete;
            this.packages = data.packages;
            this.loading = false;
          })
    },
    performDelete() {
      this.$axios.delete(
          process.env.VUE_APP_API_URL + "/settings/services/api/" + this.service.id
      ).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.closeModal();
        this.$EventBus.$emit("refreshServices");
      });
    },
    closeModal() {
      this.$emit("closed");
      $("#deleteServiceModal").modal("hide");
    }
  },
  mounted () {
    this.checkSafeToDelete()
  },
};
</script>
